import React from 'react'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { fontSizes } from 'utils/typography'
import SecondaryParagraph from 'components/text/secondary-paragraph'

import AddToSlackButton from 'components/buttons/add-to-slack'

const IndexPage = () => {
  return (
    <Layout>
      <div>
        <h1>Pricing</h1>

        <p
          css={css`
            color: #333;
          `}
        >
          Toast is{' '}
          <b
            css={css`
              color: #3ab54b;
            `}
          >
            free
          </b>{' '}
          until you agree it adds value.
        </p>
        <p>
          Large teams pay{' '}
          <b
            css={css`
              color: #3ab54b;
            `}
          >
            $4
          </b>{' '}
          monthly per active seat{' '}
          <div
            css={css`
              font-size: ${fontSizes.small1};
            `}
          >
            (inspired by Slack's{' '}
            <a href="https://get.slack.help/hc/en-us/articles/218915077-Fair-Billing-Policy">
              fair billing model
            </a>
            ).{' '}
          </div>
        </p>
        <p>
          Toast will{' '}
          <b
            css={css`
              color: #3ab54b;
            `}
          >
            stay free
          </b>{' '}
          for:
          <ul
            css={css`
              list-style: square inside;
              font-size: ${fontSizes.small1};
              margin: 0;
              > li {
                margin-bottom: 0.05rem;
              }
            `}
          >
            <li>teams of 5 and under</li>
            <li>open source projects</li>
            <li>academic pursuits</li>
          </ul>
        </p>

        {/* <hr
        css={css`
          margin-top: 1rem;
        `}
      /> */}
        <div>
          <AddToSlackButton size={60} />
          <SecondaryParagraph>
            Toast cannot read your Slack messages.
          </SecondaryParagraph>
        </div>

        {/* <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          img {
            width: 2rem;
            height: 2rem;
            margin-top: 2rem;
          }
        `}
      >
        $49/mo
        <img src="images/192w/review.png" />
        <img src="images/192w/changes.png" />
        <img src="images/192w/merge.png" />
        <img src="images/192w/love.png" />
      </div> */}
      </div>
    </Layout>
  )
}

export default IndexPage
